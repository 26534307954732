import React from "react";
import { Reveal } from "react-reveal";
import { ContentContainer } from "./Content.css";

const Content = ({ content }) => {
  return (
    <Reveal bottom>
      <ContentContainer>{content}</ContentContainer>
    </Reveal>
  );
};

export default Content;
