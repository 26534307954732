import React, { Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { MainContainer } from "./App.css";
import NavBar from "./components/NavBar/NavBar";

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import Loading from "./components/Loading/Loading";
import ErrorBoundary from "./ErrorBoundary";
import Flights from "./routes/Flights/Flights";
import { ToastContainer } from "react-toastify";


const Home = React.lazy(() => import('./routes/Home/Home'));
const About = React.lazy(() => import('./routes/About/About'));
const People = React.lazy(() => import('./routes/People/People'));
const Careers = React.lazy(() => import('./routes/Careers/Careers'));
const Contact = React.lazy(() => import('./routes/Contact/Contact'));
const Agent = React.lazy(() => import('./routes/Agent/Agent'));
const Partner = React.lazy(() => import('./routes/Partner/Partner'));
const Footer = React.lazy(() => import('./components/Footer/Footer'));
const Award = React.lazy(() => import('./routes/Awards/Awards'));

const ScrollToTop = ({ children }) => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return <>{children}</>
}


function App() {
  const queryClient = new QueryClient()

  return (

    <QueryClientProvider client={queryClient}>
      <ScrollToTop>
        <ToastContainer />
        <NavBar />
        <MainContainer>
          <ErrorBoundary>
            <Routes>
              <Route exact path="/" element={<Suspense fallback={<Loading />}><Home /></Suspense>} />
              <Route exact path="/about" element={<Suspense fallback={<Loading />}><About /></Suspense>} />
              <Route exact path="/awards" element={<Suspense fallback={<Loading />}><Award /></Suspense>} />
              <Route exact path="/people" element={<Suspense fallback={<Loading />}><People /></Suspense>} />
              <Route exact path="/careers" element={<Suspense fallback={<Loading />}><Careers /></Suspense>} />
              <Route exact path="/contact" element={<Suspense fallback={<Loading />}><Contact /></Suspense>} />
              <Route exact path="/agent" element={<Suspense fallback={<Loading />}><Agent /></Suspense>} />
              <Route exact path="/partner" element={<Suspense fallback={<Loading />}><Partner /></Suspense>} />
              <Route exact path="/flights" element={<Suspense fallback={<Loading />}><Flights /></Suspense>} />
            </Routes>
          </ErrorBoundary>
        </MainContainer>
        <Suspense fallback={<Loading />}>
          <Footer /></Suspense>
      </ScrollToTop>
    </QueryClientProvider>


  );
}

export default App;
