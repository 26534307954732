import styled from "styled-components"

export const ErrorMessageContent = styled.div`
    color: red;
    font-family: "Karla";
    font-weight: 400 ;
    font-size:24px ;
    text-align:center;

    @media only screen and (max-width:670px) {
    font-size: 20px;  
    }

`

export const ErrorMessageContainer = styled.div`
    height: 80px;
    width: 600px;
    margin: 0 auto ;
    box-shadow: 1px 1px 10px 1px #00000040;
    display: grid ;
    place-items:center ;

    @media only screen and (max-width:670px) {
     width:100% ;
    }

`

export const ErrorContainer = styled.div`
    width:100% ;
    margin:30px 0 ;
    padding: 0 8% ;
    `

