import React, { useState } from "react";
import { useQuery } from "react-query";
import Content from "../../components/Content/Content";
import HeaderTitle from "../../components/HeaderTItle/HeaderTitle";
import Loading from "../../components/Loading/Loading";
import PageBanners from "../../components/PageBanners/PageBanners";
import { apiCall } from "../../services/axiosGet";
import { PaddingContainer } from "../About/About.css";
import { Document, Page, pdfjs } from "react-pdf";
import {
  FlightCard,
  FlightImage,
  FlightName,
  FlightsContainer,
  FlightStatusCard,
  PDFModal,
  PDFModalContainer,
  StatusContainer,
  PdfView,
  Close,
} from "./Flights.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
const Flights = () => {
  const flightBanner = useQuery(
    "flightBanner",
    () => {
      return apiCall(`${process.env.REACT_APP_API_BASE}/content/flight/`);
    },
    { staleTime: 500000 }
  );
  const flights = useQuery(
    "flights",
    () => {
      return apiCall(`${process.env.REACT_APP_API_BASE}/flights/`);
    },
    { staleTime: 500000 }
  );
  const flightStatus = useQuery(
    "flightStatus",
    () => {
      return apiCall(`${process.env.REACT_APP_API_BASE}/flightstatus/`);
    },
    { staleTime: 500000 }
  );

  const PdfViewer = ({ file }) => {
    const [numPages, setNumPages] = useState(null);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
    return (
      <PdfView>
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </PdfView>
    );
  };

  const FlightsCard = ({ flight }) => {
    const [isOpen, setIsOpen] = useState(false);
    let fileExtension = flight?.flightFile.split(".").pop();
    isOpen ? disableBodyScroll(document) : enableBodyScroll(document);
    return (
      <>
        <FlightCard
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <FlightName>{flight.flightName}</FlightName>
          {fileExtension === "pdf" ? (
            <FlightImage
              src={
                "https://www.techadvisor.com/cmsdata/features/3684045/downloadpdf1_thumb800.jpg"
              }
            />
          ) : (
            <FlightImage src={flight.flightFile} />
          )}
        </FlightCard>
        {isOpen && (
          <>
            {fileExtension === "pdf" ? (
              <PDFModalContainer>
                <PDFModal>
                  <Close
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  />
                  <PdfViewer file={flight?.flightFile} />
                </PDFModal>
              </PDFModalContainer>
            ) : (
              <Lightbox
                mainSrc={flight?.flightFile}
                onCloseRequest={() => setIsOpen(false)}
                imageCaption={flight.flightName}
              />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div>
      {flightBanner.isLoading ? (
        <Loading />
      ) : (
        <>
          {flightBanner.error ? (
            <ErrorMessage />
          ) : (
            <>
              <PageBanners
                bannerImage={flightBanner?.data?.data?.bannerImage}
                bannerText={flightBanner?.data?.data?.bannerText}
              />
              <Content content={flightBanner?.data?.data?.content} />
              <PaddingContainer>
                {flights.isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {flights.error ? (
                      <ErrorMessage />
                    ) : (
                      <>
                        {flights?.data.data.length !== 0 && (
                          <>
                            <HeaderTitle
                              titleHeader={
                                "INTERNATIONAL FLIGHT SCHEDULE TO / FROM NEPAL"
                              }
                            />
                            <FlightsContainer>
                              {flights?.data.data.map((flight, index) => (
                                <FlightsCard flight={flight} key={index} />
                              ))}
                            </FlightsContainer>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </PaddingContainer>
              <PaddingContainer>
                {flightStatus.isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {flightStatus.error ? (
                      <ErrorMessage />
                    ) : (
                      <>
                        {flightStatus?.data.data.length !== 0 && (
                          <>
                            <HeaderTitle titleHeader={"Daily Flight Status"} />
                            <StatusContainer>
                              {flightStatus?.data.data.map((status, index) => (
                                <FlightStatusCard
                                  key={index}
                                  onClick={() => {
                                    window.open(status.statusLink, "_blank");
                                  }}
                                >
                                  {status.flightName}
                                </FlightStatusCard>
                              ))}
                            </StatusContainer>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </PaddingContainer>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Flights;
