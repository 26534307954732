import React from "react";
import { Reveal } from "react-reveal";
import {
  CarouselComponent,
  CarouselContents,
  CarouselHeader,
  CarouselTexts,
} from "./PageBanners.css";

const PageBanners = ({ bannerText, bannerImage }) => {
  return (
    <CarouselComponent>
      <CarouselContents backgroundImage={bannerImage}></CarouselContents>
      {bannerText !== null && (
        <CarouselTexts>
          <CarouselHeader>
            <Reveal bottom>{bannerText}</Reveal>
          </CarouselHeader>
        </CarouselTexts>
      )}
    </CarouselComponent>
  );
};

export default PageBanners;
