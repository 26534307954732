import React from "react";
import { Button, LoadingButton, SpinnerCircular } from "./Buttons.css";

const Buttons = ({ buttonText, handleClick, styles, type, loading }) => {
  return (
    <Button onClick={handleClick} {...styles} type={type} disabled={loading}>
      {loading ? (
        <LoadingButton>
          <SpinnerCircular />
          Sending
        </LoadingButton>
      ) : (
        <LoadingButton>{buttonText}</LoadingButton>
      )}
    </Button>
  );
};

export default Buttons;
