import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { RiMenuFoldLine as MenuIcon } from "react-icons/ri";
import { ImCross } from "react-icons/im"
export const NavContainer = styled.div`
    height:100px;
    width:100% ;
    background-color: rgb(28,42,88,0.0);
    z-index:1;
    position: relative;
    padding: 10px 8%;
    display: flex ;
    align-items: center ;
    justify-content:space-between ;
`

export const NavLogo = styled.img`
    height:125% ;
    @media (max-width: 880px) {
      height: 100%;
    }
`

export const NavLinks = styled.nav`
     @media (max-width: 880px) {
        display: none;
  }
`


export const LinkItem = styled(Link)`
   height:100%;

`

export const NavLinkItem = styled(NavLink)`
    text-decoration:none;
    color:  ${(props) => props.active ? "#EF5928" : "white"};
    font-family: "Montserrat";
    margin-left: 20px ;
    transition: 500ms;
    font-size: 18px;
    &:first-child{
        margin-left:0 ;
    }
    &:hover{
        color:#EF5928;
    }

    @media (max-width: 880px) {
    margin: auto auto ;
    font-size: 32px;

    &:first-child{
        margin-left:auto ;
    }
}

`

export const MenuBar = styled.div`
  display: none;
  box-sizing:border-box;
  flex-direction: column;
  width:100%;
  background-color: rgb(28,42,88);
  height: 100vh;
  top:0;
  bottom:0 ;
  position: fixed;
  z-index:200;
  transition: 500ms;
  padding: 1rem ;

  
  @media (max-width: 880px) {
    display: flex;

    ${({ show }) => show ? `
    right:0;
  `: `right:-150%;
        `}
  }

  

  
`;

export const MobileNavLinks = styled.div`
  height: 100%;
  display: none;
  margin-left: auto;

  

  color: white;
  @media (max-width: 880px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  
`;

export const Menu = styled(MenuIcon)`
  transition: 500ms;
  font-size: 40px;
  color: white;
  &:hover{
        color:#EF5928;
    }

  @media (max-width: 425px) {
    font-size: 32px;
  }
`;

export const Close = styled(ImCross)`
  font-size: 40px;
  color: white;
  transition: 500ms;
  &:hover{
        color:#EF5928;
    }

  @media (max-width: 425px) {
    font-size: 32px;
  }
`;

export const NavLinksContainer = styled.div`
    display: flex ;
    flex-direction:column ;
    height: 50% ;
    margin: auto auto ;
    width:100%;
`;

