import React from "react";
import {
  ErrorContainer,
  ErrorMessageContainer,
  ErrorMessageContent,
} from "./ErrorMessage.css";

const ErrorMessage = () => {
  return (
    <ErrorContainer>
      <ErrorMessageContainer>
        <ErrorMessageContent>
          Couldn't Load Data. Please Contact Administrator
        </ErrorMessageContent>
      </ErrorMessageContainer>
    </ErrorContainer>
  );
};

export default ErrorMessage;
