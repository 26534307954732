import React, { useEffect, useState } from "react";
import {
  Close,
  Menu,
  MenuBar,
  MobileNavLinks,
  NavContainer,
  NavLinkItem,
  NavLinks,
  NavLinksContainer,
  NavLogo,
  LinkItem,
} from "./NavBar.css";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useLocation } from "react-router-dom";
const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  showMenu ? disableBodyScroll(document) : enableBodyScroll(document);

  const menuFunc = () => {
    setShowMenu(!showMenu);
  };

  const hidemenuFunc = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    if (width > 880) {
      setShowMenu(false);
    }

    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const location = useLocation();

  return (
    <NavContainer>
      <LinkItem to={"/"}>
        <NavLogo src={process.env.PUBLIC_URL + "/UTTLogo.png"} />
      </LinkItem>
      <MobileNavLinks onClick={menuFunc}>
        <Menu />
      </MobileNavLinks>
      <NavLinks>
        <NavLinkItem to={"/"} active={location.pathname === "/"}>
          Home
        </NavLinkItem>
        <NavLinkItem to={"/about"} active={location.pathname === "/about"}>
          About Us
        </NavLinkItem>
        <NavLinkItem to={"/awards"} active={location.pathname === "/awards"}>
          Awards
        </NavLinkItem>
        <NavLinkItem to={"/people"} active={location.pathname === "/people"}>
          People
        </NavLinkItem>
        <NavLinkItem to={"/careers"} active={location.pathname === "/careers"}>
          Careers
        </NavLinkItem>
        <NavLinkItem to={"/contact"} active={location.pathname === "/contact"}>
          Contact Us
        </NavLinkItem>
      </NavLinks>
      <MenuBar show={showMenu}>
        <Close onClick={hidemenuFunc} />
        <NavLinksContainer>
          <NavLinkItem
            to={"/"}
            onClick={hidemenuFunc}
            active={location.pathname === "/"}
          >
            Home
          </NavLinkItem>
          <NavLinkItem
            to={"/about"}
            onClick={hidemenuFunc}
            active={location.pathname === "/about"}
          >
            About Us
          </NavLinkItem>
          <NavLinkItem
            to={"/awards"}
            onClick={hidemenuFunc}
            active={location.pathname === "/awards"}
          >
            Awards
          </NavLinkItem>
          <NavLinkItem
            to={"/people"}
            onClick={hidemenuFunc}
            active={location.pathname === "/people"}
          >
            People
          </NavLinkItem>
          <NavLinkItem
            to={"/careers"}
            onClick={hidemenuFunc}
            active={location.pathname === "/careers"}
          >
            Careers
          </NavLinkItem>
          <NavLinkItem
            to={"/contact"}
            onClick={hidemenuFunc}
            active={location.pathname === "/contact"}
          >
            Contact Us
          </NavLinkItem>
        </NavLinksContainer>
      </MenuBar>
    </NavContainer>
  );
};

export default NavBar;
