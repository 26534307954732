import React from "react";
import {
  LoaderDiv,
  LoaderImg,
  LoaderLine,
  LoadingContainer,
} from "./Loading.css";

const Loading = () => {
  return (
    <LoadingContainer>
      <LoaderDiv>
        <LoaderImg src={process.env.PUBLIC_URL + "/UTTLoading.png"} />
        <LoaderLine />
      </LoaderDiv>
    </LoadingContainer>
  );
};

export default Loading;
