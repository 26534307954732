import styled from "styled-components";
import Markdown from "markdown-to-jsx";

export const PaddingContainer = styled.div`
  padding: 0 8%;
  margin: 60px 0;
  background-color: ${(props) => props.backgroundColor};
`;

export const BannerContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 8%;
  padding-top: 100px;
  position: relative;
`;

export const Parallax = styled.div`
  /* The image used */
  background-image: url(${(props) => props.backgroundImage});
  height: 100vh;
  width: 100%;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(28, 42, 88, 0.15);
  background-blend-mode: darken;
  z-index: 0;
  display: flex;
  flex-direction: column;
  padding: 0 8%;
  padding-top: 100px;
  position: relative;
`;

export const BannerHeader = styled.h2`
  font-family: "Montserrat";
  font-weight: 700;
  color: #ef5928;
  font-size: 48px;
  text-transform: uppercase;
  @media only screen and (max-width: 670px) {
    font-size: 36px;
  }
  @media only screen and (max-width: 300px) {
    font-size: 10vw;
  }
`;

export const BannerContentContainer = styled.div``;

export const ContentContainer = styled(Markdown)`
  color: white;
  font-family: "Karla";
  font-weight: 400;
  font-size: 24px;
  text-align: justify;
  margin-top: 30px;

  @media only screen and (max-width: 670px) {
    font-size: 20px;
  }
`;
