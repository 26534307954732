import styled from "styled-components"


export const CarouselComponent = styled.div`
    z-index:0;
    position: relative;
    margin-top: -100px; 
    min-height: 40vh;
`

export const CarouselContents = styled.div`
    z-index:-10;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    background-image: url(${(props) => props.backgroundImage});
    background-position: center;
    background-color: rgb(28,42,88,0.45);
    background-blend-mode: darken;
    background-size: cover;
    background-repeat: no-repeat;
    @media only screen and (max-height:800px) {
        height: 300px;
    }
    `

export const CarouselTexts = styled.div`
    height: 100%;
    width: 100% ;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 8% ;
    display: flex;
    flex-direction: column;
    justify-content: end;
    background-color: tranparent;
    color: white;
    z-index: 1;

    
    

`
export const CarouselHeader = styled.h1`
    font-style: bold;
    font-size: clamp(28px,2rem,36px);
    font-family: "Montserrat";
    margin-bottom:100px ;
    margin-top:auto ;

    @media only screen and (max-width:768px) {
        margin-bottom:70px ;
    
    }
    @media only screen and (max-width:550px) {
        margin-bottom:50px ;
    
    }
    @media only screen and (max-width:425px) {
        margin-bottom:30px ;
    
    }

`

