import styled, { keyframes } from "styled-components"

export const LoadingContainer = styled.div`
    width: 100% ;
    height:auto ;
    display: grid ;
    place-items:center ;
    padding: 20px ;
`

export const LoaderDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column ;
	
`

const rotateAnimation = keyframes`
	/* from {transform: rotateY(0deg);}
	to {transform: rotateY(360deg);} */
    0% {transform: translateY(0);}
	50% {transform: translateY(-20px);}
    100%{transform: translateY(0)}
`

const lineAnimation = keyframes`
	0% {
                left: -40%;
            }
            50% {
                left: 20%;
                width: 80%;
            }
            100% {
                left: 100%;
                width: 100%;
            }
`

export const LoaderImg = styled.img`
	width: auto;
	height: 150px;
	/* transform: rotateY(45deg); */
	animation: ${rotateAnimation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

    @media only screen and (max-width:670px) {
        height: 100px;
    }
`

export const LoaderLine = styled.div`
    width: 100%;
    height: 3px;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;


    &::before{
            content: "";
            position: absolute;
            left: -50%;
            height: 3px;
            width: 40%;
            background-color: coral;
            -webkit-animation: ${lineAnimation} 1s linear infinite;
            -moz-animation: ${lineAnimation} 1s linear infinite;
            animation: ${lineAnimation} 1s linear infinite;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
            }
	
`

