import Markdown from "markdown-to-jsx"
import styled from "styled-components"

export const ContentContainer = styled(Markdown)`
    color :black;
    font-family: "Karla";
    font-weight: 400 ;
    font-size:24px ;
    padding: 0px 8% ;
    margin: 30px auto ;
    text-align:justify ;

    @media only screen and (max-width:670px) {
    font-size: 20px;  
    }
`
