import styled, { keyframes } from "styled-components"


export const Button = styled.button`
    width:max-content ;
    min-width:100px ;
    font-size: 24px ;
    font-family: "karla" ;
    background-color:${(props) => props.backgroundColor} ;
    color: white;
    text-transform: uppercase ;
    text-align: center;
    padding:  ${(props) => props.padding ?? "15px"} ;
    border: 1px solid ${(props) => props.backgroundColor} ;
    cursor: pointer;
    transition: 300ms ease-in-out ;
    

    &:hover {
        background-color: transparent ;
        color:${(props) => props.color ? `${props.color}` : "white"};
    }

    @media only screen and (max-width:580px) {
    font-size: 18px;
     
    }

`

export const LoadingButton = styled.div`
    display: flex ;
    align-items: center ;
    justify-content:center;
`

const rotateAnimation = keyframes`
	0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`

export const SpinnerCircular = styled.div`
    border: 5px solid #EF5928; 
border-top: 5px solid white; 
border-radius: 50%;
width: 40px;
height: 40px;
animation: ${rotateAnimation} 2s linear infinite;
margin-right:16px ;
  `



