import styled from "styled-components"


export const MainContainer = styled.main`
    min-height:80vh ;
`

export const ErrorContainer = styled.div`
    width:100%;
    height: 80vh ;
    display: flex ;
    flex-direction:column ;
    text-align:center;
    justify-content: center ;
    align-items: center ;
`

export const ErrorHeader = styled.h1`
    font-family: "Montserrat";
    font-weight: 700 ;
    font-size:28px ;
    margin-bottom: 30px ;
    
    
`