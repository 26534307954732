import styled from "styled-components"
import { ImCross } from "react-icons/im"

export const FlightsContainer = styled.div`
    display: grid ;
    grid-template-columns: repeat(auto-fill,minmax(300px,1fr)) ;
    margin: 20px 0 ;
    grid-gap:30px ;
    

    @media only screen and (max-width:500px) {
        grid-template-columns: repeat(auto-fill,100%) ;
        
    }
    
`


export const FlightImage = styled.img`
    height:100% ;
    width: 100% ;
    object-fit: cover ;
    
`
export const FlightName = styled.h1`
    position: absolute ;
    font-family: "Montserrat";
    font-weight: 700 ;
    color: white ;
    text-transform: uppercase ;
    text-align: center ;
    display: grid ;
    place-items:center ;
    height: 100% ;
    width:100% ;
    background-color: rgb(28,42,88,0.45);
    transition: 300ms ease-in-out ;
    
`

export const FlightCard = styled.div`
    box-shadow: 1px 1px 10px 1px #efefef;
    cursor: pointer;
    height: 250px ;
    transition: 300ms ease-in-out ;
    overflow:hidden ;
    background-color: white ;
    position: relative ;
    
    &:hover{
        transform: scale(1.01) ;
    
        ${FlightName}{
            color:#EF5928 ;
            background:white ;
            border:1px solid  #EF5928;
        }
        
    }

`

export const StatusContainer = styled.div`
    display: grid ;
    grid-template-columns: repeat(auto-fill,minmax(200px,1fr)) ;
    margin: 20px 0 ;
    grid-gap:30px ;
    

    @media only screen and (max-width:500px) {
        grid-template-columns: repeat(auto-fill,100%) ;
        
    }
    
`



export const FlightStatusCard = styled.div`
    padding:1rem ;
    text-align: center ;
    text-transform:uppercase ;
    font-family: "Montserrat";
    cursor: pointer;
    transition: 300ms ease-in-out ;
    overflow:hidden ;
    color:#EF5928 ;
    background-color: white ;
    border:1px solid #EF5928 ;
    
    &:hover{
        transform: scale(1.05) ;
        
    }

`

export const PDFModalContainer = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    background-color:rgb(28,42,88,0.45) ;
    top:0 ;
    right:0 ;
    bottom:0 ;
    z-index:100;
  
    

`

export const PdfView = styled.div`
    height:100%;
    width:100%;
    display:grid ;
    place-items:center ;
    box-sizing:border-box ;
    font-family: "Karla";
    color:white ;
    overflow: auto;
`



export const PDFModal = styled.div`
position: absolute;
height: 100%;
width: 100%;

box-sizing:border-box ;
@media only screen and (max-width:650px) {
    padding:0rem ;
        
    }


`

export const ButtonsContainer = styled.div`
display: flex ;
gap: 20px ;
width:100% ;
flex-wrap: wrap;
text-align: center ;
justify-content:center ;


`

export const Close = styled(ImCross)`
margin:1rem;
position:absolute ;
  font-size: 26px;
  color: #EF5928;
  transition: 500ms;
  right:0 ;
  z-index:100 ;

  @media (max-width: 425px) {
    font-size: 16px;
  }
`;