import React from "react";
import { ErrorContainer, ErrorHeader } from "./App.css";
import Buttons from "./components/Buttons/Buttons";
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    buttonStyles = {
        backgroundColor: "#EF5928",
    };


    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    handleReload = e => {
        e.preventDefault();
        window.location.reload();
    }
    render() {
        if (this.state.hasError) {
            return <ErrorContainer><ErrorHeader>Something went wrong.</ErrorHeader>
                <Buttons buttonText={"Reload Page"} styles={this.buttonStyles} handleClick={this.handleReload} /></ErrorContainer>;
        }

        return this.props.children;
    }
}


export default ErrorBoundary;