import { createGlobalStyle } from "styled-components"


export const GlobalStyle = createGlobalStyle`
    *{
        padding:0;
        margin: 0;
        text-decoration: none;
        box-sizing: border-box;
    }

  
    /* width */
::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:#EF5928; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #EF5928; 
}

 
    
`